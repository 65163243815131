'use strict';

import Vue from 'vue';
import config from 'client/config';
import store from 'client/store';
import userLib from 'client/lib/user';

import { saveAs } from 'file-saver';
const { Parser, transforms: { unwind } } = require('json2csv');
const moment = require('moment');

export default (function() {
  let a = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = 'none';

  return {
    prepareReportData: function(eventDiscipline, sections = [], documentTitle = null) {
      const event = store.state.events.current
      const data = {
        title: eventDiscipline.name,
        subTitle: documentTitle,
        venue: event.venue,
        eventDate: (event.date_from ? moment(event.date_from.substring(0, 10), 'YYYY/MM/DD').format('DD/MM/YYYY')
        + (event.date_to ? ' - ' + moment(event.date_to.substring(0, 10), 'YYYY/MM/DD').format('DD/MM/YYYY') : '') : null),
        logo: eventDiscipline.logoUrl ? eventDiscipline.logoUrl : event.logoUrl,
        timestamp: moment(Date.now()).format('DD/MM/YYYY - HH:mm'),
        copyright: eventDiscipline.copyright ? eventDiscipline.copyright : event.copyright,
        sections: sections,
      };

      return data;
    },

    prepareCustomizing: function(eventDiscipline, report) {
      const event = store.state.events.current
      const user = userLib.getEventUser(event);

      switch (report.template.name) {
        case 'ScoreExpress': {
          report.template.chrome = {
            marginTop: '4.1cm',
            marginBottom: '1.6cm',
          }
          if (user.reports?.footer) {
            report.data.footerTemplate = user.reports.footer;
            if (user.reports.footerSize) {
              report.template.chrome.marginBottom = user.reports.footerSize + "cm"
            }
          }
          if (user.reports?.header) {
            report.data.headerTemplate = user.reports.header;
            if (user.reports.headerSize) {
              report.template.chrome.marginTop = user.reports.headerSize + "cm"
            }
          }

          break
        }
        case 'certificate': {
          if (user.reports.certificate) {
            report.data.certificateTemplate = user.reports.certificate;
          }
          if (user.reports.css) {
            report.data.customCss = user.reports.css;
          }
          break
        }
        case 'bib': {
          if (user.reports.bib) {
            report.data.bibTemplate = user.reports.bib;
          }
          if (user.reports.css) {
            report.data.customCss = user.reports.css;
          }
          break
        }
      }
    },

    publishPDF: function(template, fileType, eventDiscipline, sections, documentTitle = null) {
      const data = this.prepareReportData(eventDiscipline, sections, documentTitle);
      const lang = Vue.i18n.locale.substring(0, 2);

      const filename = Vue.i18n.t('report.filename.' + fileType) + '.pdf';

      const report = {
        template: {
          name: template,
        },
        options: {
          language: lang,
        },
        data,
      }

      this.prepareCustomizing(eventDiscipline, report)

      Vue.http.post(config.reporting, report, {
        responseType: 'blob'
      }).then((result) => {
        this.publishFile(result.body, filename);
      }, err => {
        console.log(err);
      });
    },

    publishReport: function(eventDiscipline, sections, type = 'results', documentTitle = null) {
      this.publishPDF('ScoreExpress', type, eventDiscipline, sections, documentTitle);
    },

    publishBIB: function(eventDiscipline, sections) {
      this.publishPDF('bib', 'bib', eventDiscipline, sections);
    },

    publishCertificates: function(eventDiscipline, sections) {
      this.publishPDF('certificate', 'awards', eventDiscipline, sections);
    },

    publishCSV2: function(data, fields, filename, unwindPaths = null, transformations = null) {
      let  transforms = transformations || [];
      if (unwindPaths) {
        transforms = [unwind({paths: unwindPaths}), ...transforms];
      }

      const json2csvParser = new Parser({fields, transforms, delimiter: ';'});
      const csv = json2csvParser.parse(data);
      if (! filename.endsWith('.csv')) {
        filename += '.csv';
      }
      this.publishFile(csv, filename, 'csv');
    },

    publishFile: function(content, name, type = 'pdf') {
      let dataType = null;
      switch (type) {
        case 'pdf':
          dataType = 'application/pdf';
          break;
        case 'csv':
          dataType = 'text/plain;charset=utf-8';
          break;
      }

      let blob = new Blob([content], {type: dataType});
      saveAs(blob, name);
    }
  };
}());
